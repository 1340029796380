import "glightbox/dist/css/glightbox.min.css";
import GLightbox from "glightbox/dist/js/glightbox.min";

export function setupGlightBox(selector) {
  window.addEventListener("DOMContentLoaded", function () {
    const lightbox = GLightbox({
      selector: selector,
    });
  });
}
