// load SCSS entry file
import "../styles/main.scss";
import "../styles/owlcarousel2/owl.carousel.scss";
import "../styles/owlcarousel2/owl.theme.default.scss";

import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";

// load dependency js
import $ from "jquery";

// load js components
import setupYoutube from "../components/SetupYoutube";
import { setupNavBar } from "../components/navBar";
import { setupGlightBox } from "../components/GLightbox";
import { setupCommunityPage } from "../components/setupCommunityPage";

const blackbird = {
  setupYoutube: setupYoutube,
  setupGlightBox: setupGlightBox,
  setupCommunityPage: setupCommunityPage,
};

// expose as global object so we can call method directly on the page
window.blackbird = blackbird;

$(document).ready(function () {
  setupNavBar();
});

$(document).ready(function () {
  $("body").scroll(function () {
    if ($(this).scrollTop() > 300) {
      $("#bouncing-arrow").fadeOut();
    }
  });

  const phoneCtaCloseBtn = $(".phone-callout-card-close");
  const phoneCalloutBtn = $(".phone-callout-button");
  const phoneCalloutCard = $(".phone-callout-card");

  const hasManuallyClosed = window.localStorage.getItem(
    "phone-callout-manual-close"
  );

  if (hasManuallyClosed) {
    phoneCalloutBtn.addClass("show");
    phoneCalloutCard.addClass("hide");
  } else {
    phoneCalloutCard.addClass("show");
    phoneCalloutBtn.addClass("hide");
  }

  phoneCtaCloseBtn.click(function (e) {
    window.localStorage.setItem("phone-callout-manual-close", true);
    phoneCalloutCard.removeClass("show").addClass("hide");
    phoneCalloutBtn.removeClass("hide").addClass("show");
  });

  phoneCalloutBtn.click(function (e) {
    phoneCalloutCard.removeClass("hide").addClass("show");
    phoneCalloutBtn.removeClass("show").addClass("hide");
  });
});

// register Alpine components
import { autoLoadAlpineComponents } from "../components/alpineLoader";
autoLoadAlpineComponents();

// setup PhotoSwipe
window.addEventListener("DOMContentLoaded", function () {
  // https://github.com/dimsemenov/PhotoSwipe/issues/741
  setTimeout(function () {
    // Wait for js empty stack
    const galleryLinks = document.querySelectorAll(".photo-swipe-gallery a"); // Get all links
    galleryLinks.forEach((element) => {
      let image = new Image();
      image.src = element.getAttribute("href");
      image.onload = () => {
        // Wait for image to load
        element.dataset.pswpWidth = `${image.naturalWidth}`;
        element.dataset.pswpHeight = `${image.naturalHeight}`;
        image = null; // Free resource so garbage collector can clean it
      };
    });
  }, 1);

  const lightbox = new PhotoSwipeLightbox({
    gallery: ".photo-swipe-gallery",
    children: "a",
    pswpModule: () => import("photoswipe"),
  });
  lightbox.on("openingAnimationStart", () => {
    // stop the owl carousel autoplay
    window.$owl?.trigger("stop.owl.autoplay");
  });
  lightbox.on("closingAnimationEnd", () => {
    // resume the owl carousel autoplay
    window.$owl?.trigger("play.owl.autoplay");
  });
  lightbox.init();
});

import htmx from "htmx.org";
window.htmx = htmx;
if (process.env.NODE_ENV === "development") {
  // enable logging
  window.htmx.logAll();
}
