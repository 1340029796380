import $ from "jquery";

export function setupCommunityPage() {
  $(document).ready(function () {
    // only work on desktop devices
    if (window.innerWidth >= 992) {
      // if scroll to the listing-page-section, append url hash
      const observer = new IntersectionObserver(
        function (entries) {
          // isIntersecting is true when element and viewport are overlapping
          // isIntersecting is false when element and viewport don't overlap
          if (entries[0].isIntersecting === true) {
            history.pushState({}, "", "#listing-page-section");
          }
        },
        { threshold: [0] }
      );

      observer.observe(document.querySelector("#listing-page-section"));

      // auto scroll
      // https://stackoverflow.com/a/3163626
      if (window.location.hash === "#listing-page-section") {
        $(document.body).animate(
          {
            scrollTop: $("#listing-page-section").offset().top,
          },
          500
        );
      }
    }
  });
}
