import { sleep } from "../components/Utils";

export default function ReadMoreSection() {
  return {
    hasMore: false,
    section: this.$refs.section,

    checkHasMore() {
      // if the last char is …</p>
      let text = this.section.dataset.text;
      let lastStr = text.length >= 5 ? text.slice(-5) : text;
      if (lastStr === "…</p>") {
        this.hasMore = true;
      }
    },

    async readMoreClick(event) {
      event.preventDefault();

      this.section.innerHTML = `<div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
        <span class="sr-only">Loading...</span>
      </div>`;
      await sleep(1000);

      this.section.innerHTML = this.section.dataset.fullText;
    },
  };
}
