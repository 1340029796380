export default function () {
  return {
    init() {
      // autofill email field from the localStorage
      const form = this.$el;

      const email = window.localStorage.getItem("email");
      const emailFields = form.querySelectorAll('input[type="email"]');
      if (email) {
        emailFields.forEach((field) => {
          // set email to the emailField
          field.value = email;
        });
      }

      const phone = window.localStorage.getItem("phone");
      const phoneFields = form.querySelectorAll("input[placeholder='Phone']");
      if (phone) {
        phoneFields.forEach((field) => {
          field.value = phone;
        });
      }
    },
  };
}
