import $ from "jquery";

function setupMultiLevelNavBar() {
  // https://bootstrap-menu.com/_bootstrap4/detail-multilevel.html

  // Prevent closing from click inside dropdown
  $(document).on("click", ".dropdown-menu", function (e) {
    e.stopPropagation();
  });

  // make it as accordion for smaller screens
  // https://bootstrap-menu.com/_bootstrap4/detail-multilevel.html
  if (window.innerWidth < 992) {
    $(".dropdown-menu .dropdown-item").click(function (e) {
      if ($(this).next(".submenu").length) {
        e.preventDefault();
        $(this).next(".submenu").toggle();
      }
    });

    $(".navbar-nav li.dropdown").on("hide.bs.dropdown", function (event) {
      // if top dropdown is closed, nested submenu should also be closed
      const liElement = event.target;
      $(liElement).find(".submenu").hide();
    });
  }
}

function autoOpenNavBar() {
  // Auto open Nav Dropdown list on hover
  // https://bootstrap-menu.com/_bootstrap4/detail-basic-hover.html
  // https://bootstrap-menu.com/_bootstrap4/detail-multilevel.html
}

export function setupNavBar() {
  setupMultiLevelNavBar();
  autoOpenNavBar();
}
