import $ from "jquery";
import Cookie from "js-cookie";

function csrfSafeMethod(method) {
  // these HTTP methods do not require CSRF protection
  return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
}

function getFormData($form) {
  var unindexed_array = $form.serializeArray();
  var indexed_array = {};

  $.map(unindexed_array, function (n, i) {
    indexed_array[n["name"]] = n["value"];
  });

  return indexed_array;
}

/**
 * Manually push form success event to dataLayer. This is needed
 * because we are preventing default event bubbling for AJAX request.
 *
 * @param {*} formId
 */
function pushFormSuccessToDataLayer(formId) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "form.submit.success",
    formId: formId,
  });
}

// Set CSRFToken on Ajax header
$.ajaxSetup({
  beforeSend: function (xhr, settings) {
    if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
      xhr.setRequestHeader("X-CSRFToken", Cookie.get("csrftoken"));
    }
  },
});

function sleep(ms) {
  return new window.Promise((resolve) => setTimeout(resolve, ms));
}

function serializeForm(data) {
  // convert FormData to object
  let obj = {};
  for (let [key, value] of data) {
    if (obj[key] !== undefined) {
      if (!Array.isArray(obj[key])) {
        obj[key] = [obj[key]];
      }
      obj[key].push(value);
    } else {
      obj[key] = value;
    }
  }
  return obj;
}

export { getFormData, pushFormSuccessToDataLayer, sleep, serializeForm };
