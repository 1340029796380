// https://developers.google.com/youtube/iframe_api_reference
// https://stackoverflow.com/questions/54017100/how-to-integrate-youtube-iframe-api-in-reactjs-solution

import $ from "jquery";
import MobileDetect from "mobile-detect";

let tv;
const playerDefaults = {
  autoplay: 0,
  autohide: 1,
  modestbranding: 0,
  rel: 0,
  showinfo: 0,
  controls: 0,
  disablekb: 1,
  enablejsapi: 0,
  iv_load_policy: 3,
  loop: 1,
};

let vid;
let randomVid;
let currVid;
let num_of_play;

function onYouTubePlayerAPIReady() {
  let md = new MobileDetect(window.navigator.userAgent);
  if (md.mobile()) {
    $(".tv").addClass("d-none");
    $(".no-video").removeClass("d-none");
  } else {
    var w = $(window).width() + 200,
      h = $(window).height() + 200;
    var tvw, tvh, tvshift;
    if (w / h > 16 / 9) {
      tvw = w;
      tvh = (w / 16) * 9;
      tvshift = false;
    } else {
      tvw = (h / 9) * 16;
      tvh = h;
      tvshift = true;
    }
    tv = new window.YT.Player("tv", {
      width: tvw,
      height: tvh,
      events: {
        onReady: onPlayerReady,
        onStateChange: onPlayerStateChange,
        onError: onError,
      },
      playerVars: playerDefaults,
    });
    if (tvshift) {
      $(".tv .screen").css({
        left: "0px",
      });
    } else {
      $(".tv .screen").css({
        left: -($(".tv .screen").outerWidth() - w) / 2,
      });
    }
  }
}

function onError(e) {
  tv.stopVideo();

  $(".tv").addClass("d-none");
  $(".no-video").removeClass("d-none");
}

function onPlayerReady() {
  vid = [
    {
      videoId: window.videoId,
      suggestedQuality: "hd720",
    },
  ];
  randomVid = Math.floor(Math.random() * vid.length);
  currVid = randomVid;
  num_of_play = 0;

  tv.loadVideoById(vid[currVid]);
  tv.mute();
}

function onPlayerStateChange(e) {
  if (e.data === window.YT.PlayerState.PLAYING) {
    if (!$(".tv .screen").hasClass("active")) {
      $(".tv .screen").addClass("active");
    }
  }

  if (e.data === 0 || e.data === 2) {
    num_of_play++;

    tv.seekTo(0);
    tv.playVideo();
  }
}

// function vidRescale() {
//   var w = $(window).width() + 200,
//     h = $(window).height() + 200;
//   if (w / h > 16 / 9) {
//     tv.setSize(w, w / 16 * 9);
//     $('.tv .screen').css({
//       'left': '0px'
//     });
//   } else {
//     tv.setSize(h / 9 * 16, h);
//     $('.tv .screen').css({
//       'left': -($('.tv .screen').outerWidth() - w) / 2
//     });
//   }
// }
//
// $(window).on('load resize', function() {
//     vidRescale();
// });

export default function setupYoutube(videoId) {
  window.videoId = videoId;

  if (!window.YT) {
    // If not, load the script asynchronously
    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";

    window.onYouTubeIframeAPIReady = onYouTubePlayerAPIReady;

    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  } else {
    // If script is already there, load the video directly
    onYouTubePlayerAPIReady();
  }
}
