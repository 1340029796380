export default function Map() {
  return {
    init() {
      const { latitude, longitude, title } = this.$el.dataset;
      const L = window.L;

      if (latitude && longitude && title && window.L) {
        const map = window.L.map(this.$el).setView([latitude, longitude], 13);

        if (window.L.Browser.mobile) {
          map.dragging.disable();
        }

        map.scrollWheelZoom.disable();
        map.removeControl(map.zoomControl);

        L.tileLayer(
          "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
          {
            maxZoom: 18,
            id: "mapbox/streets-v11",
            accessToken:
              "pk.eyJ1IjoidHJpZWRhbCIsImEiOiJjamR0NmFzMmowbGo5MnBtdXFuOWc3c2F6In0.B_AJGViuGH6uMN0uuUW6XA",
          }
        ).addTo(map);

        L.marker([latitude, longitude])
          .addTo(map)
          .bindPopup(`${title}`)
          .openPopup();
      }
    },
  };
}
