import { pushFormSuccessToDataLayer } from "../components/Utils";

export default function () {
  return {
    init() {
      const element = this.$el;

      element.addEventListener("htmx:configRequest", function (evt) {
        if (evt.detail.verb === "post") {
          // add extra parameters to the HTMX POST request
          evt.detail.parameters = {
            ...evt.detail.parameters,
            ...{ location: window.location.href },
          };

          // TODO: refactor
          // write value to localstorage
          const { email, phone } = evt.detail.parameters;
          if (email) {
            window.localStorage.setItem("email", email);
          }
          if (phone) {
            window.localStorage.setItem("phone", phone);
          }
        }
      });

      element.addEventListener("htmx:afterSwap", function (evt) {
        const messageEle = element.querySelector(
          "form textarea[placeholder='Message']"
        );
        if (messageEle && messageEle.value === "") {
          element.querySelector("form textarea[placeholder='Message']").value =
            element.dataset.defaultMsg;
        }
      });

      element.addEventListener("htmx:afterRequest", function (evt) {
        if (evt.detail.successful && !evt.target.querySelector("form")) {
          pushFormSuccessToDataLayer("home_type_contact_form");
        }
      });
    },
  };
}
