export default function () {
  return {
    init() {
      const element = this.$el;

      element.addEventListener("htmx:configRequest", function (evt) {
        if (evt.detail.verb === "get") {
          const trackData = JSON.parse(element.dataset.formData);

          // add extra parameters to the HTMX GET request
          const keysToPick = ["date", "time", "property.street"];
          const data = Object.fromEntries(
            Object.entries(trackData).filter(([key, value]) =>
              keysToPick.includes(key)
            )
          );
          evt.detail.parameters = { ...evt.detail.parameters, ...data };
        }
      });

      element.addEventListener("htmx:configRequest", function (evt) {
        if (evt.detail.verb === "post") {
          const trackData = JSON.parse(element.dataset.formData);

          // add extra parameters to the HTMX POST request
          evt.detail.parameters = { ...evt.detail.parameters, ...trackData };

          // TODO: refactor
          // write value to localstorage
          const { email, phone } = evt.detail.parameters;
          if (email) {
            window.localStorage.setItem("email", email);
          }
          if (phone) {
            window.localStorage.setItem("phone", phone);
          }
        }
      });
    },
  };
}
