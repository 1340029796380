import $ from "jquery";

export default function () {
  return {
    init() {
      const $takeTourModal = $("#take-tour-modal");
      const $formModal = $("#take-tour-request-modal");
      const formModal = document.querySelector("#take-tour-request-modal");

      // reset modal (user might submit more than once on the page)
      $takeTourModal.on("show.bs.modal", function (e) {
        $(".modal-dialog-take-tour .error-message")
          .html("Please select date and time.")
          .css("opacity", "0");

        // here we set display to empty so code in css would work
        $(".modal-dialog-take-tour")
          .find(".modal-content-item")
          .css("display", "");
      });

      $("#btn-schedule-tour").click(function (e) {
        e.preventDefault();

        let pickedTime = $(".time-picker select").val();
        let checkFlag = false;

        if ($("div.date-picker-item.active").length && pickedTime) {
          checkFlag = true;
        }

        if (checkFlag) {
          // validation pass
          $(".modal-dialog-take-tour .error-message").css("opacity", "0");

          let formData = {
            time: pickedTime,
            date: $("div.date-picker-item.active").data("value"),
            location: window.location.href,
          };

          if ($("#blackbird_track_data").length) {
            const trackData = JSON.parse($("#blackbird_track_data").text());
            formData = Object.assign(formData, trackData);
          }

          // set form data to the modal for the next step
          $takeTourModal.modal("hide");
          formModal.dataset.formData = JSON.stringify(formData);

          $formModal.modal("show");
        } else {
          $(".modal-dialog-take-tour .error-message").css("opacity", "1");
        }
      });
    },
  };
}
