import $ from "jquery";

export default function DateChooser() {
  return {
    items: [],
    pos: 0,
    itemCount: 0,

    touchStartX: 0,
    touchEndX: 0,
    touchStartY: 0,
    touchEndY: 0,

    init() {
      const instance = this;
      this.items = $(".date-picker-item");

      // because we display 3 items in the slider
      this.itemCount = this.items.length - 3;
      this.pos = 0;

      // pre button
      $(".take-tour-pre").click(function () {
        instance.sliderPre();
      });

      // next button
      $(".take-tour-next").click(function () {
        instance.sliderNext();
      });

      // setup
      instance.items.click(function () {
        // all remove active class
        instance.items.removeClass("active");
        // set active class
        $(this).addClass("active");
      });

      // setup swipe gesture
      const elements = this.$el.querySelectorAll(".date-picker-item");
      elements.forEach(function (item) {
        item.addEventListener("touchstart", function (event) {
          // Get the x position of the touch
          instance.touchStartX = event.touches[0].clientX;
          instance.touchStartY = event.touches[0].clientY;
        });

        item.addEventListener("touchend", function (event) {
          // Get the x and y position of the touch
          instance.touchEndX = event.changedTouches[0].clientX;
          instance.touchEndY = event.changedTouches[0].clientY;

          // Determine the distance and duration of the swipe
          const swipeDistanceX = instance.touchEndX - instance.touchStartX;
          const swipeDistanceY = instance.touchEndY - instance.touchStartY;
          const swipeDuration = event.changedTouches[0].duration;

          // Determine the direction of the swipe
          if (
            Math.abs(swipeDistanceX) > Math.abs(swipeDistanceY) &&
            Math.abs(swipeDistanceX) > 50
          ) {
            if (swipeDistanceX < 0) {
              // The swipe was a left swipe
              // console.log('Left swipe detected!');
              instance.sliderNext();
            } else {
              // The swipe was a right swipe
              // console.log('Right swipe detected!');
              instance.sliderPre();
            }
          } else if (Math.abs(swipeDistanceY) > 50) {
            if (swipeDistanceY < 0) {
              // The swipe was an up swipe
              // console.log('Up swipe detected!');
            } else {
              // The swipe was a down swipe
              // console.log('Down swipe detected!');
            }
          }
        });
      });
    },

    setTransform() {
      // do slide operation
      // left_margin + width + right_margin = 78px
      this.$el.style.transform = `translate(-${this.pos * 78}px, 0px)`;
    },

    sliderPre() {
      this.items.removeClass("active");
      this.pos = Math.max(this.pos - 3, 0);
      this.setTransform();
    },

    sliderNext() {
      this.items.removeClass("active");
      this.pos = Math.min(this.pos + 3, this.itemCount);
      this.setTransform();
    },
  };
}
