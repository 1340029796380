import $ from "jquery";

const KEY = "close-listing-detail-welcome-modal-timestamp";

function checkModalFromTimestamp() {
  // check localStorage, if user already close modal within 5 days
  // do not show the modal again
  let timestamp = window.localStorage.getItem(KEY);
  if (!timestamp) {
    // if localstorage have no record, show the modal
    return true;
  }

  const dateTime = new Date(parseInt(timestamp)).getTime();
  const now = new Date().getTime();
  const diff = now - dateTime;
  return diff > 1000 * 60 * 60 * 24 * 5;
}

export default function () {
  return {
    init() {
      // only work on listing-detail-page
      if (!$("body.listing-detail-page").length) return;

      const $targetModal = $(this.$el);

      if (checkModalFromTimestamp()) {
        $targetModal.modal("show");
      }

      $targetModal.on("hidden.bs.modal", function (e) {
        window.localStorage.setItem(KEY, new Date().getTime().toString());
      });
    },
  };
}
